import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { ArrowRight, DollarSign, Briefcase, Users } from "react-feather"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import WorldMap from "../components/worldmap"
//import EnablerForm from "../components/enabler-form"

const Enablers = () => (
  <>
    <Layout>
      <div id="landing-top">
        <div id="contains-map">
          <div id="map-container">
            <WorldMap />
          </div>
        </div>

        {/* <div id="room-meta">
        
    </div> */}
        <div id="room-col">
          <div id="room-box">
            <div id="room-content">
              <iframe
                src="https://remotehour.com/widget/qBKXer032fWh38dQCQ7QFXt26TF2/qHy4hEeblPQfqbknVR2u?inline=true"
                width="100%"
                height="560"
                marginwidth="0"
                marginheight="0"
                frameborder="0"
                allow="microphone; camera"
              ></iframe>
            </div>
          </div>
        </div>
        {/* <div id="right-col">

    <div id="enabler-actions">

      <AniLink fade to="/founders" duration={0.4}>
      <div className="lp-item-container">
        
        <div className="lp-item-header">
        <div className="item-icon-container">
        <Briefcase className="header-icon" />
        </div>
        Hire
          <div className="item-arrow">
          <ArrowRight/>

          </div>
        </div>
        <div className="item-description">
          <p>Are you a founder, team leader, or hiring manager that can offer remote or visa sponsored jobs that support relocation for unleveraged talent? If so, hire.</p>
        </div>

      </div>
      </AniLink>

      <AniLink fade to="/founders" duration={0.4}>
      <div className="lp-item-container">
        
        <div className="lp-item-header">
        <div className="item-icon-container">
        <Users className="header-icon" />
        </div>
        Mentor
          <div className="item-arrow">
          <ArrowRight/>
          </div>
        </div>
        <div className="item-description">
          <p>Are you in the tech industry and would like to assist immigrant founders in creating, building, and advancing their skills, careers, and companies?</p>
        </div>

      </div>
      </AniLink>

      <AniLink fade to="/founders" duration={0.4}>
      <div className="lp-item-container">
        
        <div className="lp-item-header">
        <div className="item-icon-container">
        <DollarSign className="header-icon" />
        </div>
        Invest
          <div className="item-arrow">
          <ArrowRight/>
          </div>
        </div>
        <div className="item-description">
          <p>Are you an angel investor or venture capitalist and would like to discover hidden unleverage talent that could potentially be the next Musk?</p>
        </div>

      </div>
      </AniLink>

      </div>
    </div> */}
      </div>
    </Layout>
  </>
)

export default Enablers
